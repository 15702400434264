<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con bg">
          <div class="detail_top">
            <div
              class="market_thumb"
              style="margin-bottom: 1rem;"
              v-if="!$Util.isEmpty(detail.thumbnail)"
            >
              <swiper
                :slides-per-view="1"
                :centered-slides="true"
                :speed="500"
                :autoplay="{ delay: 3000 }"
              >
                <swiper-slide
                  v-for="(item, index) in detail.thumbnail"
                  :key="index"
                >
                  <div class="img_area">
                    <img :src="item" alt="" />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="market_thumb" style="margin-bottom: 1rem;" v-else>
              <div class="img_area">
                <img src="@/assets/static/images/no_image.png" alt="" />
              </div>
            </div>
            <div class="detailwrap" style="margin-bottom: 0;">
              <div class="market_ttl" style="padding-top: 0;">
                {{ detail.title }}
              </div>
              <div class="party_keyword" v-if="!$Util.isEmpty(searchWords)">
                <span v-for="(item, index) in searchWords" :key="index">{{
                  `#${item}`
                }}</span>
              </div>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap">
              <dl>
                <dt>구매가능 수량</dt>
                <dd v-if="detail.ownerClose === 'Y'">판매 완료</dd>
                <dd v-else>{{ detail.remainQuantity }}개</dd>
              </dl>
              <dl>
                <dt>판매가</dt>
                <dd class="price">
                  <div
                    class="percent"
                    :class="
                      computedPer(detail.price, detail.commonPrice).className
                    "
                  >
                    {{ computedPer(detail.price, detail.commonPrice).per }}
                  </div>
                  <span class="daily">{{
                    $Util.formatNumber2(detail.price, { unit: '' })
                  }}</span
                  >원
                  <span class="gray_price">{{
                    $Util.formatNumber2(detail.commonPrice, { unit: '' })
                  }}</span>
                </dd>
              </dl>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap">
              <div class="flex">
                <div
                  class="prof"
                  @click="fnModalAlertSellerProfile"
                  :class="[
                    {
                      off: leader.userLvNm === $ConstCode.LEVEL_TYPE['0'].name
                    },
                    {
                      vip: leader.sellerLv >= 3
                    },
                    {
                      nonebg:
                        !$Util.isEmpty(leader.profile) &&
                        leader.profile.indexOf('/null') < 0
                    }
                  ]"
                >
                  <!-- 프로필이미지 지정시 nonebg 추가 -->
                  <div class="img_area">
                    <img
                      :src="leader.profile"
                      alt=""
                      v-if="
                        !$Util.isEmpty(leader.profile) &&
                          leader.profile.indexOf('/null') < 0
                      "
                    />
                  </div>
                </div>
                <div @click="fnModalAlertSellerProfile" class="nickname">
                  {{ leader.userNm }}
                </div>
                <div @click="fnModalAlertRefundPolicy" class="right">
                  환불 정책 안내
                </div>
              </div>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap note">
              <dl>
                <dt>상품 소개 및 주의사항</dt>
                <dd>
                  <div class="intro">
                    <span>{{ detail.contents }}</span>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="detail.type == '1'" class="default">
            <div class="detailwrap note">
              <dl>
                <dt>구매자 요청정보</dt>
                <dd>
                  <div class="req_options">
                    <div class="option" v-if="detail.reqKakao == '1'">
                      카카오톡ID
                    </div>
                    <div class="option" v-if="detail.reqPhone == '1'">
                      휴대폰번호
                    </div>
                    <div class="option" v-if="detail.reqEmail == '1'">
                      이메일
                    </div>
                  </div>
                  <div class="color_secondary">
                    판매자가 상품전달을 위해 구매자에게 요청하는 정보입니다.
                  </div>
                  <div class="color_secondary">
                    결제단계에서 해당 정보를 입력하면 판매자에게 전달됩니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="default">
            <div class="detailwrap note attention">
              <dl>
                <dt>주의하세요!</dt>
                <dd>
                  <div>
                    * 구매 상품에 문제가 있을 경우 당사자 간에 해결이 필요하며,
                    위즈니는 상품 등록을 위한 시스템만 제공합니다.
                  </div>
                  <div>
                    * 판매자 과실(연락두절 및 이용 불가능한 상품 판매 등)로 인해
                    취소요청이 진행 될 경우 위즈니에서 환불을 진행해드립니다.
                    (구매확정 전 취소요청 진행 시)
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="join_area">
            <div
              v-if="detail.remainQuantity != 0 && detail.ownerClose === 'N'"
              class="inner"
            >
              <div class="top">
                <label class="checkbox st02">
                  <input
                    type="checkbox"
                    :false-value="'N'"
                    :true-value="'Y'"
                    v-model="checkYn"
                  />
                  <span class="label"
                    ><span>상품정보와 주의사항을 모두 숙지했습니다.</span></span
                  >
                </label>
              </div>
              <div class="btm market">
                <div>
                  <button class="btn market" @click="fnPage">구매 하기</button>
                </div>
              </div>
            </div>
            <div v-else class="inner">
              <div class="btm white">
                <div class="completed">해당 상품은 판매 완료됐습니다.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nice
        :id="'formNice18'"
        :m="niceForm.m"
        :encode-data="niceForm.encodeData"
        :call-type="niceForm.callType"
        :rtn-url="rtnUrl"
        :fail-rtn-url="failRtnUrl"
        :callback="fnCallbackNice"
      />
    </div>
  </main>
</template>
<script>
import { componentState } from './index.js'

export default {
  name: 'market-view-index',
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style lang="scss" scoped>
.market_detail_img {
  display: block;
  max-height: 360 * 0.1rem;
  max-width: 650 * 0.1rem;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
