import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { CommonFunction } from '@/common/CommonFunction'
import { fnModalAlert } from '@/components/common/modal/alert'
import { niceForm } from '@/common/form/NiceForm'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    searchWords: computed(() => {
      if (proxy.$Util.isEmpty(state.detail.searchWord)) return []
      return state.detail.searchWord.split(',')
    }),
    form: {},
    marketNo: route.query.marketNo,
    detail: {},
    leader: {},
    checkYn: 'N',
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })

  const computedPer = (price, commonPrice) => {
    const getDiff = price / commonPrice
    let per = ''
    let className = {}
    if (getDiff < 1) {
      per = '-' + per + Math.round((1 - getDiff) * 100) + '%'
      className = { per: true }
    } else if (getDiff > 1) {
      per = '+' + per + Math.round((getDiff - 1) * 100) + '%'
      className = { perplus: true }
    } else if (getDiff == 1) {
      per = '0%'
      className = { per: true }
    }

    return { per, className }
  }

  const { fnMyInfo, fnUpdateAuthType } = CommonFunction(state)

  const fnCallbackNice = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({
        path: '/market/pay',
        query: {
          marketNo: state.detail.marketNo,
          marketAgree: state.checkYn
        }
      })
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }

  const fnModalAlertRefundPolicy = () => {
    const payload = {
      component: proxy.$modalAlertNames.REFUND_POLICY,
      data: {
        typeName: 'MARKET'
      },
      callback: () => {}
    }
    fnModalAlert(payload)
  }

  const fnModalAlertSellerProfile = () => {
    const payload = {
      component: proxy.$modalAlertNames.SELLER_PROFILE,
      data: {
        userNm: state.leader.userNm,
        userLvNm: state.leader.userLvNm,
        sellerLv: state.leader.sellerLv,
        profile: state.leader.profile,
        productNo: route.query.marketNo,
        productType: 'MARKET',
        userData: state.userData
      },
      callback: () => {}
    }
    fnModalAlert(payload)
  }

  const fnPage = async () => {
    if (proxy.$Util.isEmpty(state.userData)) {
      await router.push({ path: '/login' })
      return false
    }
    if (state.checkYn !== 'Y') {
      alert('상품정보 및 주의사항 숙지 여부를 체크해주세요')
      return false
    }
    if (
      proxy.$Util.isEmpty(state.userData.authType) ||
      !(
        Number(state.userData.authType) === 2 ||
        Number(state.userData.authType) === 4
      )
    ) {
      alert('결제 구매를 위해 휴대폰 인증이 필요합니다.')
      await fnMyInfo()
      return false
    }

    await fnModalAlertChoosePinNumber()
  }

  const fnModalAlertChoosePinNumber = async () => {
    const payload = {
      component: proxy.$modalAlertNames.CHOOSE_PIN_NUMBER,
      data: {
        title: state.detail.title,
        price: state.detail.price,
        marketNo: state.marketNo
      },
      callback: productList => {
        productList = productList.reduce(
          (pv, cv) => [...pv, { seq: cv.seq, modDt: cv.modDt }],
          []
        )
        router.push({
          name: 'market-pay-index',
          query: {
            marketNo: state.detail.marketNo,
            marketAgree: state.checkYn,
            productList: JSON.stringify(productList)
          }
        })
      }
    }
    await fnModalAlert(payload)
  }

  const fnEdit = async () => {
    await router.replace({
      path: '/my-page/market/edit',
      query: {
        marketNo: state.detail.marketNo
      }
    })
  }

  const fnMarketDetail = async () => {
    const res = await proxy.$MarketSvc.postMarketDetail({
      marketNo: state.marketNo
    })
    if (res.resultCode === '0000') {
      state.detail = res.detail
      state.leader = res.leader
    } else {
      alert(res.resultMsg)
    }
  }

  const init = async () => {
    await fnMarketDetail()
    if (state.detail.myMarketYn === 'Y') {
      const query = {
        marketNo: state.marketNo
      }
      await router.replace({
        name: 'my-page-market-view-index',
        query
      })
      return
    }
  }

  init()

  return {
    ...toRefs(state),
    fnPage,
    fnEdit,
    fnCallbackNice,
    computedPer,
    fnModalAlertRefundPolicy,
    fnModalAlertSellerProfile
  }
}
